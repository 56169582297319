import "./App.css";
import DialogProvider from "./components/dialogs/DialogProvider";
import { Navigate, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useAppDispatch, useAppSelector } from "./store";
import { Suspense, useEffect, useState, lazy } from "react";
import {
  ASSET_SUITE_LOGIN_URL,
  COOKIE_DETAILS,
  SESSION_KEY,
  SESSION_TYPE,
} from "./config/default.config";
// import { postCrossDomainMessage } from './store/keycloak/token-services';
import { sessionStorageUtils } from "./utils/session-storage.utils";
import { encryptUtils } from "./utils/encryption.utils";
import { cookieUtils } from "./utils/cookie.utils";
import { createContext } from "react";
import { validateToken } from "./store/keycloak/asset-keycloak.action";
import Navbar from "./components/navbar.component/navbar";
import { setTheme } from "./store/seqr-ops/seqr-ops.slice";
import { IUserAuthorizations } from "./store/keycloak/asset-keycloak.interface";
import { queryBuilderActions } from "./store/query-builder/query-builder.actions";
import { ToastContainer } from "react-toastify";
interface ThemeContextType {
  theme: string;
  toggleTheme: () => void;
}

// Lazy load components
const Home = lazy(() => import("./components/home.component/home"));
const AllSubStations = lazy(() => import("./components/substation.component/all-substations"));
const GISWrapper = lazy(() => import("./modules/gis/GISWrapper"));
const Dashboard = lazy(() => import("./modules/dashboard/dashboard"));
const SubStation = lazy(() => import("./components/substation.component/substation"));
const Transformer = lazy(() => import("./components/Transformer.component/transformer"));
const Customer = lazy(() => import("./components/customer.component/customer"));
const AlarmListComponentNew = lazy(() =>
  import("./components/alarm.list.component/alarm-list-component-new")
);
const OpenEMSComponent = lazy(() => import("./components/oems.componenet/openems.component"));

export const ThemeContext = createContext<ThemeContextType | null>(null);

function App() {
  const loading = useAppSelector((state) => state.seqrOps.loadingState.loading);
  const theme = useAppSelector((state) => state.seqrOps.theme);
  const [ready, setReady] = useState<boolean>(false);
  const [localThemeState, setLocalThemeState] = useState<string>("");
  const authorozedScopes = useAppSelector((state) => state.assetKeycloak);
  const [userScopesFromLocalStorage, setUserScopesFromLocalStorage] =
    useState<IUserAuthorizations>();
  const [userName, setUserName] = useState<string>("");
  const [loggedUser, setLoggedUser] = useState<string>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    console.log("Changes reflected : Performance upgrade");
    // const cleanup = handleMqttMessages();
    const validateAuth = async () => {
      let cookieName = COOKIE_DETAILS.COOKIE_NAME;
      let encryptedCookieData = cookieUtils.getCookie(cookieName);
      let decryptedCookieData = encryptUtils.decryptURL(encryptedCookieData);

      if (decryptedCookieData?.themeType === "LIGHT") {
        dispatch(setTheme(false));
      } else if (decryptedCookieData?.themeType === "DARK") {
        dispatch(setTheme(true));
      }
      if (
        decryptedCookieData?.sessionId != null &&
        decryptedCookieData?.sessionId !== undefined &&
        decryptedCookieData?.sessionId !== ""
      ) {
        setUserName(decryptedCookieData?.username);
        const validation = await validateToken(
          decryptedCookieData?.sessionId,
          setReady
        );
        if (!validation) {
          setReady(false);
          localStorage.clear();
          cookieUtils.deleteCookie(cookieName);
          window.location.replace(ASSET_SUITE_LOGIN_URL);
        }
      } else if (
        decryptedCookieData?.sessionId == null ||
        decryptedCookieData?.sessionId === undefined ||
        decryptedCookieData?.sessionId === ""
      ) {
        setReady(false);
        localStorage.clear();
        cookieUtils.deleteCookie(cookieName);
        window.location.replace(ASSET_SUITE_LOGIN_URL);
      }
    };

    //for setting username
    if (
      sessionStorageUtils.getLocalStorage(SESSION_KEY.LOCAL_STORAGE_KEY) !==
      null
    ) {
      let tokenData: any = sessionStorageUtils.getLocalStorage(
        SESSION_KEY.LOCAL_STORAGE_KEY
      );
      if (tokenData !== null) {
        setUserScopesFromLocalStorage(tokenData?.token?.userAuthorizations);
        setLoggedUser(tokenData?.token?.username);
      }
    }
    validateAuth();
    // return () => {
    //   cleanup();
    // };
  }, []);

  const fetchWeatherData = () => {
    dispatch(queryBuilderActions.getWeatherData());
  };

  useEffect(() => {
    fetchWeatherData();

    const intervalId = setInterval(() => {
      fetchWeatherData();
    }, 10 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (theme) {
      setLocalThemeState("dark");
    } else {
      setLocalThemeState("light");
    }
  }, [theme]);

  if (!ready) {
    return null;
  }

  return (
    <div className="App" id={localThemeState}>
      <div className="seqrops-navbar-section">
        <Navbar
          loggeduser={
            userScopesFromLocalStorage !== undefined
              ? loggedUser
              : authorozedScopes.username
          }
          userScopesFromLocalStorage={userScopesFromLocalStorage}
        />
      </div>
      <DialogProvider>
      <Suspense fallback={<div className="loading-spinner">Loading...</div>}>
        <Routes>
          <Route path="/" element={<Home />}>
            <Route index element={<AllSubStations />} />
            <Route path="/map" element={<GISWrapper />} />
            <Route path="/dashboard" element={<Dashboard />}></Route>
            {/* <Route path="/map" element={<Map />}></Route> */}
            <Route path="/substation/:id" element={<SubStation />} />
            <Route path="/transformer/:id" element={<Transformer />} />
            <Route path="/consumer/:id" element={<Customer />} />
            <Route path="/alarms" element={<AlarmListComponentNew />} />
            <Route path="/EMS/:id" element={<OpenEMSComponent />} />
            
          </Route>
        </Routes>
        </Suspense>
      </DialogProvider>
      <ToastContainer
        position="bottom-right"
        autoClose={4000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {loading ? (
        <div className="loader-row">
          <div className="loader">
            <div id="ld4">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default App;