import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ISubstationDataRecord, ISubstations, ISubstationsDataPayload } from "./substation.interface";

const initialState: ISubstations = {
  substations:{},
  allSubstations:{}
};
const substationSlice = createSlice({
  name: "substationSlice",
  initialState: initialState,
  reducers: {
    setSubstationDataRecords: (
      state,
      action: PayloadAction<ISubstationsDataPayload>
    ) => {
      const { id, value } = action.payload;
      if(id){
        if (!state.substations[id]) {
          state.substations[id] ={
            substation:[],
            percentile:[],
            circuitPower:[],
            lastFetched:0
          }
        }
        state.substations[id].substation = value?.substation;
        state.substations[id].percentile = value?.percentile;

      }
    },
    setSubstationCircuitDataRecords: (
      state,
      action: PayloadAction<ISubstationsDataPayload>
    ) => {

      const { id, value } = action.payload;
      if(id){
        if (!state.substations[id]) {
          state.substations[id] ={
            substation:[],
            percentile:[],
            circuitPower:[],
            lastFetched:0
          }
        }
        state.substations[id].circuitPower = value?.circuitPower;

      }

    },
    setSubstationLastFetched: (
      state,
      action: PayloadAction<{id:string}>
    ) => {

      const { id } = action.payload;
      if(id){
        if (!state.substations[id]) {
          state.substations[id] ={
            substation:[],
            percentile:[],
            circuitPower:[],
            lastFetched:0
          }
        }
        state.substations[id].lastFetched = Date.now();

      }

    },

    setAllSubstationData: (
      state,
      action: PayloadAction<any>
    ) => {

      state.allSubstations = action.payload

    },

  },
});

export const { setSubstationDataRecords, setSubstationCircuitDataRecords, setSubstationLastFetched, setAllSubstationData } =
  substationSlice.actions;
export default substationSlice.reducer;
