import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ICustomerData,ICustomers, ICustomersDataPayload } from "./customer.interface";

const initialState: ICustomers = {
  customers:{},
  // consumer: [],
  // connectivityData: [],
  // storageSummary: [],
  // pvData: [],
  // pvSummary: [],
  // cunsumerDataCheck: false,
};
const customerSlice = createSlice({
  name: "customerSlice",
  initialState: initialState,
  reducers: {
    setCustomerDataRecords: (state, action: PayloadAction<ICustomersDataPayload>) => {
      const { id, value } = action.payload;
      if(id){
        if (!state.customers[id]) {
          state.customers[id] = {
            consumer:[],
            connectivityData:[],
            storageSummary:[],
            pvData:[],
            pvSummary:[],
            lastFetched:0,
            cunsumerDataCheck:false
          }
        }
      state.customers[id].consumer = value.consumer;
      state.customers[id].connectivityData = value?.connectivityData;
      state.customers[id].storageSummary = value?.storageSummary;
      state.customers[id].pvData = value?.pvData;
      state.customers[id].pvSummary = value?.pvSummary;
      state.customers[id].lastFetched = value?.lastFetched;
      if (
        value?.consumer.length > 0 &&
        value?.connectivityData.length > 0 &&
        value?.pvData.length > 0 &&
        value?.pvSummary.length > 0 &&
        value?.storageSummary.length > 0
      ) {
        state.customers[id].cunsumerDataCheck = true;
      }
      }
      
    },
    setCustomerLoadsDataRecords: (
      state,
      action: PayloadAction<ICustomersDataPayload>
    ) => {
      const { id, value } = action.payload;
      if(id){
        if (!state.customers[id]) {
          state.customers[id] = {
            consumer:[],
            connectivityData:[],
            storageSummary:[],
            pvData:[],
            pvSummary:[],
            lastFetched:0,
            cunsumerDataCheck:false
          }
        }
      state.customers[id].consumer = value?.consumer;
      if (value?.consumer.length > 0) {
        state.customers[id].cunsumerDataCheck = true;
      }
      }
      
    },
    setCustomerPvDataRecords: (state, action: PayloadAction<ICustomersDataPayload>) => {
      const { id, value } = action.payload;
      if(id){
        if (!state.customers[id]) {
          state.customers[id] = {
            consumer:[],
            connectivityData:[],
            storageSummary:[],
            pvData:[],
            pvSummary:[],
            lastFetched:0,
            cunsumerDataCheck:false
          }
        }
      state.customers[id].pvData = value?.pvData;
      if (value?.pvData.length > 0) {
        state.customers[id].cunsumerDataCheck = true;
      }
      }
      
    },
    setCustomerBusesDataRecords: (
      state,
      action: PayloadAction<ICustomersDataPayload>
    ) => {
      const { id, value } = action.payload;
      if(id){
        if (!state.customers[id]) {
          state.customers[id] = {
            consumer:[],
            connectivityData:[],
            storageSummary:[],
            pvData:[],
            pvSummary:[],
            lastFetched:0,
            cunsumerDataCheck:false
          }
        }
      state.customers[id].connectivityData = value?.connectivityData;
      if (value?.connectivityData.length > 0) {
        state.customers[id].cunsumerDataCheck = true;
      }
      }
      
    },
    setCustomerStorageSummaryDataRecords: (
      state,
      action: PayloadAction<ICustomersDataPayload>
    ) => {
      const { id, value } = action.payload;
      if(id){
        if (!state.customers[id]) {
          state.customers[id] = {
            consumer:[],
            connectivityData:[],
            storageSummary:[],
            pvData:[],
            pvSummary:[],
            lastFetched:0,
            cunsumerDataCheck:false
          }
        }
      state.customers[id].storageSummary = value?.storageSummary;
      if (value?.storageSummary.length > 0) {
        state.customers[id].cunsumerDataCheck = true;
      }
      }
      
    },
    setCustomerPvSummaryDataRecords: (
      state,
      action: PayloadAction<ICustomersDataPayload>
    ) => {
      const { id, value } = action.payload;
      if(id){
        if (!state.customers[id]) {
          state.customers[id] = {
            consumer:[],
            connectivityData:[],
            storageSummary:[],
            pvData:[],
            pvSummary:[],
            lastFetched:0,
            cunsumerDataCheck:false
          }
        }
      state.customers[id].pvSummary = value?.pvSummary;
      if (value?.pvSummary.length > 0) {
        state.customers[id].cunsumerDataCheck = true;
      }
      }
      
    },
    setCustomerLastFetched: (
      state,
      action: PayloadAction<{id:string}>
    ) => {

      const { id } = action.payload;
      if(id){
        if (!state.customers[id]) {
          state.customers[id] = {
            consumer:[],
            connectivityData:[],
            storageSummary:[],
            pvData:[],
            pvSummary:[],
            lastFetched:0,
            cunsumerDataCheck:false
          }
        }
        state.customers[id].lastFetched = Date.now();

      }

    },
  },
});

export const {
  setCustomerDataRecords,
  setCustomerLoadsDataRecords,
  setCustomerPvDataRecords,
  setCustomerBusesDataRecords,
  setCustomerPvSummaryDataRecords,
  setCustomerStorageSummaryDataRecords,
  setCustomerLastFetched
} = customerSlice.actions;
export default customerSlice.reducer;
