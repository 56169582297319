import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IModelData, ITrans, ITransformer, ITransformerData, LastFetchedPayload, TransformDataInterface, TransformerDataPayload } from "./transformer.interface";

const initialState: ITrans = {
transformer:{}
};
const transformerSlice = createSlice({
  name: "transformerSlice",
  initialState: initialState,
  reducers: {
    setTransformerDataRecords: (
      state,
      action: PayloadAction<TransformerDataPayload>
    ) => {

      const { id, value } = action.payload;

  if(id) {
    if (!state.transformer[id]) {
      state.transformer[id] = { lines:[],
        connectivity: [],
        storageSummary: [],
        consumerSummary: [],
        pvSummary: [],
        consumers:[],
        acLineSegment:[],
        connectivityNodes:[],
        consumerData:[],
        pvData:[],
        storageData:[],
        transformerDataCheck:false,
        lastFetched: 0
      }
    }
      state.transformer[action.payload.id].consumers = action?.payload?.value.consumers;
      state.transformer[action.payload.id].lines = action?.payload?.value.lines;
      state.transformer[action.payload.id].connectivity = action?.payload?.value.connectivity;
      state.transformer[action.payload.id].acLineSegment = action?.payload?.value.acLineSegment;
      state.transformer[action.payload.id].connectivityNodes = action?.payload?.value.connectivityNodes;
      state.transformer[action.payload.id].pvData = action?.payload?.value.pvData;
      state.transformer[action.payload.id].consumerData = action?.payload?.value.consumerData;
      state.transformer[action.payload.id].storageData = action?.payload?.value.storageData;

      if (
        action?.payload?.value.acLineSegment.length > 0 &&
        action?.payload?.value.connectivityNodes.length > 0 &&
        action?.payload?.value.pvData.length > 0 &&
        action?.payload?.value.consumerData &&
        action?.payload?.value.storageData.length > 0
      ) {
        state.transformer[action.payload.id].transformerDataCheck = true;
      }
    }
      // state.battery = action?.payload?.battery;
    },
    setTransformerEnergyConsumerDataRecords: (
      state,
      action: PayloadAction<TransformerDataPayload>
    ) => {
      // state.transformer[action.payload.id].consumerData = action?.payload?.value.consumerData;
      // console.log(state.transformer)
      

  const { id, value } = action.payload;

  if(id) {
    if (!state.transformer[id]) {
      state.transformer[id] = { lines:[],
        connectivity: [],
        storageSummary: [],
        consumerSummary: [],
        pvSummary: [],
        consumers:[],
        acLineSegment:[],
        connectivityNodes:[],
        consumerData:[],
        pvData:[],
        storageData:[],
        transformerDataCheck:false,
        lastFetched: 0
      }
    }

        state.transformer[id].consumerData = value?.consumerData ?? [];
        console.log(state.transformer,"transformer datas >>>>>>>>>>>>>>>>")
        if (action?.payload?.value?.consumerData.length > 0) {
          state.transformer[id].transformerDataCheck = true;
        }
        }
      

      // state.battery = action?.payload?.battery;
    },
    setTransformerBatteryUnitDataRecords: (
      state,
      action: PayloadAction<TransformerDataPayload>
    ) => {
      const { id, value } = action.payload;
      if(id) {
        if (!state.transformer[id]) {
          state.transformer[id] = { lines:[],
            connectivity: [],
            storageSummary: [],
            consumerSummary: [],
            pvSummary: [],
            consumers:[],
            acLineSegment:[],
            connectivityNodes:[],
            consumerData:[],
            pvData:[],
            storageData:[],
            transformerDataCheck:false,
            lastFetched: 0 ,
          }
        }
        state.transformer[id].storageData = value.storageData;

        if (value?.storageData.length > 0) {
          state.transformer[id].transformerDataCheck = true;
        }
        
      }      

      // state.battery = action?.payload?.battery;
    },
    setTransformerPvUnitDataRecords: (
      state,
      action: PayloadAction<TransformerDataPayload>
    ) => {

      const { id, value } = action.payload;
      if(id) {
        if (!state.transformer[id]) {
          state.transformer[id] = { lines:[],
            connectivity: [],
            storageSummary: [],
            consumerSummary: [],
            pvSummary: [],
            consumers:[],
            acLineSegment:[],
            connectivityNodes:[],
            consumerData:[],
            pvData:[],
            storageData:[],
            transformerDataCheck:false,
            lastFetched: 0,
          }
        }
        state.transformer[id].pvData = value?.pvData;

        if (value?.pvData.length > 0) {
          state.transformer[id].transformerDataCheck = true;
        }
      }


      // state.battery = action?.payload?.battery;
    },
    setTransformerBusesDataRecords: (
      state,
      action: PayloadAction<TransformerDataPayload>
    ) => {

      const { id, value } = action.payload;
      if(id) {
        if (!state.transformer[id]) {
          state.transformer[id] = { lines:[],
            connectivity: [],
            storageSummary: [],
            consumerSummary: [],
            pvSummary: [],
            consumers:[],
            acLineSegment:[],
            connectivityNodes:[],
            consumerData:[],
            pvData:[],
            storageData:[],
            transformerDataCheck:false,
            lastFetched: 0,
          }
        } 
        state.transformer[id].connectivityNodes = value.connectivityNodes;

      if (value.connectivityNodes.length > 0) {
        state.transformer[id].transformerDataCheck = true;
      }

      }

    },
    setTransformerLinesDataRecords: (
      state,
      action: PayloadAction<TransformerDataPayload>
    ) => {

      const { id, value } = action.payload;
      if(id) {
        if (!state.transformer[id]) {
          state.transformer[id] = { lines:[],
            connectivity: [],
            storageSummary: [],
            consumerSummary: [],
            pvSummary: [],
            consumers:[],
            acLineSegment:[],
            connectivityNodes:[],
            consumerData:[],
            pvData:[],
            storageData:[],
            transformerDataCheck:false,
            lastFetched: 0,
          }
        } 
        state.transformer[id].acLineSegment = value?.acLineSegment;

        if (value?.acLineSegment?.length > 0) {
          state.transformer[id]
          .transformerDataCheck = true;
        }
      }

    },
    setTransformerLasftFetched: (
      state,
      action: PayloadAction<LastFetchedPayload>
    ) => {

      const {id}  = action.payload;
      if(id) {
        if (!state.transformer[id]) {
          state.transformer[id] = { lines:[],
            connectivity: [],
            storageSummary: [],
            consumerSummary: [],
            pvSummary: [],
            consumers:[],
            acLineSegment:[],
            connectivityNodes:[],
            consumerData:[],
            pvData:[],
            storageData:[],
            transformerDataCheck:false,
            lastFetched: 0 ,
          }
        } 
        state.transformer[id].lastFetched = Date.now()
      }
      
    },
    
  },
});

export const {
  setTransformerDataRecords,
  setTransformerEnergyConsumerDataRecords,
  setTransformerBatteryUnitDataRecords,
  setTransformerPvUnitDataRecords,
  setTransformerBusesDataRecords,
  setTransformerLinesDataRecords,
  setTransformerLasftFetched
} = transformerSlice.actions;
export default transformerSlice.reducer;
