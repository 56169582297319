import seqropsLogo from "../../assets/images/grid-monitoring-logo.svg";
import seqropsMenuBtn from "../../assets/images/seqrops_menu_icon.svg";
import seqropsLogoutBtn from "../../assets/images/seqrops-exit-btn.svg";
import seqropsHomeBtn from "../../assets/images/seqrops_home_btn.svg";
import seqropsModelsIcon from "../../assets/images/asset_model_icon.svg";
import gridMonitoringIcon from "../../assets/images/grid-monitoring-icon.svg";
import seqropsAnalyticalIcon from "../../assets/images/analytical-dashboard.svg";
import seqropsAssetsIcon from "../../assets/images/asset_management_icon.svg";
import fsmIcon from "../../assets/images/fsm_icon.svg";
import deviceManagementIcon from "../../assets/images/device_management_icon.svg";
import weatherTempIcon from "../../assets/images/weather-temperature.svg";
import weatherWindIcon from "../../assets/images/weather-wind.svg";

import "./navbar.scss";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  addAuthorizations,
  setTokens,
} from "../../store/keycloak/asset-keycloak.slice";
import { useDispatch } from "react-redux";
import { assetKeycloakActions } from "../../store/keycloak/asset-keycloak.action";
import {
  ILogoutTokenPayload,
  ITokenDetails,
  IUserAuthorizations,
  IUserTokenDetails,
} from "../../store/keycloak/asset-keycloak.interface";
import { useAppSelector } from "../../store";
import { setLogin, setTheme } from "../../store/seqr-ops/seqr-ops.slice";
import {
  ASSET_REDIRECT_SCOPES,
  ASSET_SUITE_LOGIN_URL,
  ASSET_URLS,
  CLEAR_CLIENT_SCOPES,
  COOKIE_DETAILS,
  DASHBOARD_TYPE,
  KEYCLOAK_CLIENTS,
  SESSION_KEY,
} from "../../config/default.config";
import { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { sessionStorageUtils } from "../../utils/session-storage.utils";
import { OverlayPanel } from "primereact/overlaypanel";
import { encryptUtils } from "../../utils/encryption.utils";
import ReactSwitch from "react-switch";
import { seqrOpsActions } from "../../store/seqr-ops/seqr-ops.action";
import { cookieUtils } from "../../utils/cookie.utils";

function Navbar(props: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const op = useRef<OverlayPanel>(null);
  const authorozedScopes = useAppSelector((state) => state.assetKeycloak);
  const weatherData = useAppSelector(
    (state) => state.queryBuilderSlice.weatherData
  );
  const [showDialog, setShowDialog] = useState(false);
  useState<IUserAuthorizations>();
  const [themeType, setThemeType] = useState("");
  const theme = useAppSelector((state) => state.seqrOps.theme);
  const [userScopesFromLocalStorage, setUserScopesFromLocalStorage] =
    useState<IUserAuthorizations>();
  const [session_id, setSession_id] = useState("");
  const setSuccess = (state: boolean) => {
    const ITokenDetails: ITokenDetails = {
      auth_token: "",
      refresh_token: "",
      client_id: "",
      client_secret: "",
      username: "",
      password: "",
      session_id: "",
    };

    dispatch(setTokens(ITokenDetails));
    dispatch(setLogin(false));
    let cookieName = `${process.env.REACT_APP_COOKIE_PREFIX}_user_session_id`;
    localStorage.clear();
    cookieUtils.deleteCookie(cookieName);
    dispatch(addAuthorizations(CLEAR_CLIENT_SCOPES));
    window.location.replace(ASSET_SUITE_LOGIN_URL);
    hideDialog();
    if (state) {
    }
  };

  interface IApplinksInterface {
    name: string;
    icon: any;
    url: string | undefined;
    scope: keyof IUserAuthorizations;
  }

  const appLinks: IApplinksInterface[] = [
    {
      name: "Home",
      icon: seqropsHomeBtn,
      url: ASSET_URLS.APP_SUITE_URL,
      scope: "asset_suite",
    },
    {
      name: "Asset Models",
      icon: seqropsModelsIcon,
      url: ASSET_URLS.ASSET_MODELS_URL,
      scope: "asset_models",
    },
    {
      name: "Asset Management",
      icon: seqropsAssetsIcon,
      url: ASSET_URLS.ASSET_MANAGEMENT_URL,
      scope: "asset_management",
    },
    {
      name: "Grid Monitoring",
      icon: gridMonitoringIcon,
      url: ASSET_URLS.GRID_MONOTORING_APP,
      scope: "grid_monitoring_app",
    },
    {
      name: "Analytics Dashboard",
      icon: seqropsAnalyticalIcon,
      url: ASSET_URLS.ANALYTICS_DASHBOARD,
      scope: "analytics_dashboard",
    },
    {
      name: "EMS",
      icon: deviceManagementIcon,
      url: ASSET_URLS.OPEN_EMS_APP,
      scope: "ems_app",
    },
    {
      name: "Integrated Simulator Environment",
      icon: fsmIcon,
      url: "",
      scope: "ems_app",
    },
  ];

  const itemsPerRow = 3;
  const rows: any = [];
  let rowIndex = 0;

  for (const item of appLinks) {
    rows[rowIndex] = rows[rowIndex] || [];

    const scopeValue = item.scope;
    if (userScopesFromLocalStorage?.[scopeValue]) {
      rows[rowIndex].push(item);
    }

    if (rows[rowIndex].length === itemsPerRow) {
      rowIndex++;
    }
  }

  rows.forEach((row: any) => {
    row.push(
      ...Array.from({ length: Math.max(0, itemsPerRow - row.length) }, () => ({
        name: "",
        icon: "",
        url: "",
        scope: "",
      }))
    );
  });

  const handleAppClick = (domain: string, url: string) => {
    let redirectUrl = url;
    // if ([ASSET_REDIRECT_SCOPES.ANALYTICS_DASHBOARD].includes(domain)) {
    //   const queryParams = new URLSearchParams({ dashboardType: domain });
    //   const urlWithParams = `${redirectUrl}?${queryParams.toString()}`;
    //   window.open(urlWithParams, "_self");
    // } else {
    window.open(redirectUrl, "_self");
    // }
  };
  const AppClick = ({ dashboardType, icon, label, url }: any) => {
    return (
      <div
        className="col target-app-container"
        onClick={() => handleAppClick(dashboardType, url)}
      >
        <div className="">
          <div className="div">
            <img
              src={icon}
              alt={dashboardType}
              className="img-fluid asset-images"
            />
            <span className="operationalDashboardDiv seqrops-options-text">
              {label}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const handleLogOut = async () => {
    let ILogoutTokenPayload: ILogoutTokenPayload = {
      refresh_token: authorozedScopes.refresh_token,
      session_id: encryptUtils.encryptAuthKey(authorozedScopes.session_id),
    };
    await assetKeycloakActions.logoutToken(ILogoutTokenPayload, setSuccess);
  };

  const hideDialog = () => {
    setShowDialog(false);
  };

  const confirmLogout = () => {
    setShowDialog(true);
  };

  const [dashboardTypee, setDashboardTypee] = useState<string | null>(null);
  const [assetUrl, setAssetUrl] = useState<string | undefined>(undefined);
  const [siteUrl, setSiteUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    let userTokenDetails: IUserTokenDetails =
      sessionStorageUtils.getLocalStorage(SESSION_KEY.LOCAL_STORAGE_KEY)?.token;
    setSession_id(userTokenDetails?.session_id);
    if (userTokenDetails !== null) {
      dispatch(setTokens(userTokenDetails));
      setUserScopesFromLocalStorage(userTokenDetails?.userAuthorizations);
      const newArray = [];
      let data: any = userTokenDetails?.userAuthorizations;

      const urlParams = new URLSearchParams(window.location.search);
      const dashboardType = urlParams.get("redirectFrom");
      setDashboardTypee(dashboardType);
    }
  }, [dashboardTypee]);

  useEffect(() => {
    let tokenData: any = sessionStorageUtils.getLocalStorage(
      SESSION_KEY.LOCAL_STORAGE_KEY
    );
    if (tokenData !== null) {
      setUserScopesFromLocalStorage(tokenData?.token?.userAuthorizations);
    }
  }, []);

  const handleDashboardClick = (domain: string) => {
    let dashboardURL = ASSET_URLS.OPERATIONAL_DASHBOARD_URL;

    if (domain === KEYCLOAK_CLIENTS.MANAGEMENT_DASHBOARD) {
      dashboardURL = ASSET_URLS.MANAGEMENT_DASHBOARD_URL;
    }

    const queryParams = new URLSearchParams({ dashboardType: domain });
    const urlWithParams = `${dashboardURL}?${queryParams.toString()}`;
    window.open(urlWithParams, "_blank");
  };

  const DashboardLink = ({ dashboardType, icon, label }: any) => {
    return (
      <div
        className="col target-app-container"
        onClick={() => handleDashboardClick(dashboardType)}
      >
        <div className="">
          <div className="div">
            <img
              src={icon}
              alt={dashboardType}
              className="img-fluid asset-images"
            />
            <span className="operationalDashboardDiv seqrops-options-text">
              {label}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const footer = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={handleLogOut}
        autoFocus
      />
    </div>
  );

  useEffect(() => {
    let tokenData: any = sessionStorageUtils.getLocalStorage(
      SESSION_KEY.LOCAL_STORAGE_KEY
    );
    // let tokenData = encryptUtils.decryptURL(tokenDataEncrypted);
    if (tokenData !== null) {
      setUserScopesFromLocalStorage(tokenData?.token?.userAuthorizations);
      setThemeType(tokenData?.token?.themeType);
    }
  }, []);

  const toggleTheme = async (isDark: any, event: any) => {
    await seqrOpsActions.SetThemes(isDark, setReady);
  };

  const setReady = (status: boolean, isDark: boolean) => {
    if (status) {
      try {
        dispatch(setTheme(isDark));
        let theme = "";

        if (isDark) {
          theme = "DARK";
        } else if (!isDark) {
          theme = "LIGHT";
        }

        let cookieName = COOKIE_DETAILS.COOKIE_NAME;
        let domainName = COOKIE_DETAILS.COOKIE_DOMAIN;
        let sessionId = session_id;

        let sessionThemeType = {
          sessionId: sessionId,
          themeType: theme,
        };
        let encryptedSessionThemeType =
          encryptUtils.encryptURL(sessionThemeType);
        cookieUtils.setCookie(cookieName, encryptedSessionThemeType, {
          domain: domainName,
        });
      } catch { }

      //store
      //cookie
    }
  };

  return (
    <div>
      {" "}
      <div
        className="seqrops-navbar-wrapper"
        onMouseLeave={(e) => {
          op.current && op.current?.hide();
        }}
      >
        <div className="container-fluid dark-navbar">
          <div className="row">
            <div className="d-flex  align-items-center">
              <div className="seqrops-logo-section  align-items-center">
                <img src={seqropsLogo} alt="seqrops logo" />
                <span>Grid Monitoring</span>
              </div>
              <div className="d-flex weather-icons-container">
                <div className="weather-icon">
                  <img
                    src={weatherTempIcon}
                    alt={"temperature"}
                    className="img-fluid asset-images"
                  />
                  {weatherData.temperature} °C
                </div>
                <div className="weather-icon">
                  <img
                    src={weatherWindIcon}
                    alt={"wind speed"}
                    className="img-fluid asset-images"
                  />
                  {weatherData.windspeed} m/s
                </div>
              </div>
              <div className="ms-auto">
                <div className="switch d-flex align-items-centre">
                  <ReactSwitch
                    className="switchButton"
                    onChange={toggleTheme}
                    checked={theme}
                    onColor="#02457a"
                    onHandleColor="#2693e6"
                    handleDiameter={20}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={15}
                    width={40}
                    checkedHandleIcon={<MoonToggleIcon />}
                    uncheckedHandleIcon={<SunToggleIcon />}
                  />
                  <div className="seqrops-username-box">
                    <div className="seqrops-username-text">
                      {props?.loggeduser
                        ? props?.loggeduser.toUpperCase().charAt(0)
                        : null}
                    </div>
                    <div className="seqrops-username">{props?.loggeduser}</div>
                  </div>
                </div>
              </div>

              <div className="seqrops-user-section">
                <Tooltip title="menu">
                  <button className="seqrops-settings">
                    <img
                      src={seqropsMenuBtn}
                      alt="seqrops settings logo"
                      onClick={(e) => op.current && op.current?.toggle(e)}
                    />
                  </button>
                </Tooltip>
              </div>

              <OverlayPanel ref={op} id={theme ? "dark" : "light"}>
                <div className="d-flex align-items-center header-sidebar">
                  <div className="me-auto">
                    <h4>{props.loggeduser}</h4>
                  </div>
                  <div
                    className="logout-section"
                    onClick={() => confirmLogout()}
                  >
                    <span>Logout</span>
                    <img
                      src={seqropsLogoutBtn}
                      alt="Log out"
                      className="img-fluid asset-logout"
                    />
                  </div>
                </div>

                <div className="seqrops-asset-apps">
                  <div className="seqrops-asset-apps-row">
                    {rows.flat().map((link: IApplinksInterface, index: number) => {
                      const scopeValue = link.scope;
                      return (
                        <div key={index} className="target-app-container">
                          {userScopesFromLocalStorage?.[scopeValue] && (
                            <AppClick
                              dashboardType={scopeValue}
                              icon={link.icon}
                              label={link.name}
                              url={link.url}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </OverlayPanel>
            </div>
          </div>
        </div>
        <Dialog
          visible={showDialog}
          onHide={hideDialog}
          header="Confirm Logout"
          footer={footer}
          modal
          style={{ width: "350px" }}
        >
          <div>Are you sure you want to logout?</div>
        </Dialog>
      </div>
    </div>
  );
}

const SunToggleIcon = () => (
  <svg
    x="0"
    y="0"
    width="20"
    height="20"
    viewBox="0 0 102 102"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="51" cy="51" r="51" fill="#F2DB10" />
    <path
      d="M34.6428 52.5109C34.575 58.7768 35.602 62.6298 37.9348 64.9626C40.2708 67.2986 44.1618 68.3572 50.5 68.3572C56.8378 68.3572 60.6929 67.2986 63.0116 64.9649C65.3317 62.6296 66.3571 58.7727 66.3571 52.5C66.3571 46.2273 65.3317 42.3705 63.0116 40.0352C60.6929 37.7014 56.8378 36.6429 50.5 36.6429C44.1626 36.6429 40.3433 37.7013 38.0421 40.0329C35.7346 42.3707 34.7108 46.2314 34.6428 52.5109Z"
      stroke="#05314D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M50.5 25.1071V29.3214"
      stroke="#05314D"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M50.5 75.6786V79.8929"
      stroke="#05314D"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M77.8931 52.5L73.6788 52.5"
      stroke="#05314D"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M27.3213 52.5L23.107 52.5"
      stroke="#05314D"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M29.4287 31.4286L32.4087 34.4085"
      stroke="#05314D"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M29.4287 74.444L32.4087 71.464"
      stroke="#05314D"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M72.4438 31.4286L69.4639 34.4085"
      stroke="#05314D"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M72.4438 74.444L69.4639 71.464"
      stroke="#05314D"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
const MoonToggleIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 102 102"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="51" cy="51" r="51" fill="#05314D" />
    <path
      d="M75.875 61.7234L76.7995 62.1046C76.9585 61.719 76.8615 61.2753 76.5561 60.9913C76.2508 60.7072 75.8013 60.6425 75.4282 60.8288L75.875 61.7234ZM48.2829 28.12L49.0892 28.7115C49.3352 28.3761 49.3479 27.9234 49.1211 27.5747C48.8943 27.2261 48.4754 27.0542 48.0691 27.1431L48.2829 28.12ZM66.0893 63.0255C54.4056 63.0255 44.9101 53.416 44.9101 41.5325L42.9101 41.5325C42.9101 54.494 53.2746 65.0255 66.0893 65.0255L66.0893 63.0255ZM75.4282 60.8288C72.6119 62.2353 69.4428 63.0255 66.0893 63.0255L66.0893 65.0255C69.7606 65.0255 73.235 64.1597 76.3218 62.6181L75.4282 60.8288ZM74.9505 61.3423C71.4283 69.8857 63.1157 75.8749 53.4359 75.8749L53.4359 77.8749C63.9666 77.8749 72.9844 71.3585 76.7995 62.1046L74.9505 61.3423ZM53.4359 75.8749C40.5748 75.8749 30.1249 65.2975 30.125 52.22L28.125 52.22C28.1249 66.3755 39.4438 77.8749 53.4359 77.8749L53.4359 75.8749ZM30.125 52.22C30.125 40.867 38.0037 31.3935 48.4967 29.0969L48.0691 27.1431C36.6591 29.6405 28.125 39.9261 28.125 52.22L30.125 52.22ZM44.9101 41.5325C44.9101 36.7249 46.4643 32.2898 49.0892 28.7115L47.4766 27.5285C44.6072 31.44 42.9101 36.2874 42.9101 41.5325L44.9101 41.5325Z"
      fill="white"
    />
    <path
      d="M64.5937 29.8895C64.8195 29.2807 65.6805 29.2807 65.9063 29.8895L68.5817 37.1027C68.6528 37.2942 68.804 37.4452 68.9956 37.5159L76.2212 40.1833C76.8313 40.4085 76.8313 41.2714 76.2212 41.4967L68.9956 44.1641C68.804 44.2348 68.6528 44.3858 68.5817 44.5773L65.9063 51.7905C65.6805 52.3993 64.8195 52.3993 64.5937 51.7905L61.9183 44.5773C61.8472 44.3858 61.696 44.2348 61.5044 44.1641L54.2788 41.4967C53.6687 41.2714 53.6687 40.4085 54.2788 40.1833L61.5044 37.5159C61.696 37.4452 61.8472 37.2942 61.9183 37.1027L64.5937 29.8895Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default Navbar;
